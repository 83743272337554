import React from "react";
import PageHeader from "../../components/PageHeader";
import {Model} from "../../data/Model";

import "../../assets/css/enquire.css";

const Enquire = ({ history }) => {
    const tel = Model.shop_info ? Model.shop_info.phone : '02-557-3521';
    const telLink = 'tel:'+tel;
    const shopName = Model.shop_info ? Model.shop_info.branch_name : ''
  return (
    <div className="enquire_wrap">
      <PageHeader history={history} name="고객" subName="문의" />
      <div className="enquire_cont">
        <p className="text">
          WKON*AISUL Coffee를 이용해주셔서 감사합니다.
          <br />
          보다 혁신적이고 친절한 서비스를 위해 노력하고 있습니다.
          <br />
          궁금하신 사항 및 서비스 문의, 매장 개설 문의는 아래의 전화 번호를
          참고하세요.
        </p>
        <p className="time">전화상담 시간 : 평일 09:00~18:00</p>
        <p className="tel">
          <a>{shopName}</a><br />
        </p>
        <p className="tel">
          대표전화 <a href={telLink}>{tel}</a>
        </p>
      </div>
    </div>
  );
};

export default Enquire;
