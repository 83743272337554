import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loading from "../pages/Loading";
import ItemList from "../pages/List";
import Item from "../pages/Detail";
import Cart from "../pages/Cart";
import Order from "../pages/Order";
import Enquire from "../pages/Enquire";
import Login from "../pages/Login";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Loading} />
      <Route exact path="/list/" component={ItemList} />
      <Route exact path="/list/:id" component={ItemList} />
      <Route exact path="/item/:category/:id" component={Item} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/order" component={Order} />
      <Route exact path="/enquire" component={Enquire} />
      <Route exact path="/login" component={Login} />
    </Switch>
  </Router>
);
