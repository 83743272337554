import React, { useState, useEffect } from "react";
import ItemHeader from "../../components/ItemHeader";
import Item from "../../components/Item";

// 데이터를 받아와 주세요
import { getItems, getCategoryItems, getItemsId, addCart } from "../../utils/item";

import {Model, convertOrderData, addChangeListener} from "../../data/Model";

const ItemList = ({ match, history }) => {

// console.log('document.referrer:', document.referrer)
// console.log('window.location.href:', window.location.href)
//     console.log('----------------------- start ItemList ---------------------------')
  const id = match.params.id || getItemsId()[0];
  const _d = getItems(id) || {productList:[]};
  const [data, setData] = useState(_d);
  const [items, setItems] = useState(data.productList);

  if(!Model.branch_code || !Model.symbol || !Model.token) {
    history.push("/");
  }

  Model.page_reflash = () => {}
  useEffect(() => {
    const d = id ? getItems(id) : { productList: [] };
    if (d && d.length>0) { setData(d); }
    setItems(d.productList);
  }, [data, id]);

  const onChangeMenu = menu => {
    const id = getCategoryItems(menu).id;
    const d = getItems(id);
    setItems(d.productList);
  };

  const linkDepth = itemId => {
    const item = items.filter(i => i.id == itemId)[0];
    if(item.productList && item.productList.length) {
        history.push(`/list/${itemId}`);
    } else {
        addCart(item);
        history.push(`/cart`);
    }
  };

  return (
    <div className="item_wrap">
      <ItemHeader
        history={history}
        menu={data.category}
        onChangeMenu={onChangeMenu}
      />
      <ul className="item_list">
        {items.map(item => (
          <Item item={item} key={item.id} onDetail={linkDepth} />
        ))}
      </ul>
    </div>
  );
};

export default ItemList;
