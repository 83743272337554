export const nComma = (x) => {
	x = x+'';
	if(x.indexOf('.')>-1) {
		let t = x.split('.');
		console.log('t:', t);
		t[0] = t[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		t[1] = t[1] ? ('.'+t[1]).replace(/0*$/g, ''): '';
		console.log('t:', t);
		x = t[0] + t[1];
		console.log('x:', x);
	} else {
		x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}
	return x;
}

export const getURLParameter = (sParam) => {
    let r = '';
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    for (var i=0; i < sURLVariables.length; i++){
        const sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam){
            r = sParameterName[1] ? sParameterName[1] : '';
        }
	}
	return r;
}

var VanillaToasts = require('vanillatoasts');
export const showMessage = (type, text, title, timeout, callback) => {
    VanillaToasts.create({
        'title': title || '',
        'text': text || '',
        'type': type || 'info', // success, info, warning, error   / optional parameter
        // 'icon': '../assets/images/common/my.png', // optional parameter
        'timeout': timeout || ( type==='error' ? 60000 : 5000 ), // hide after 5000ms, // optional parameter
        'callback': callback || function(){} // executed when toast is clicked / optional parameter
    });
}
window.showMessage = showMessage; // global 선언.

export const return_wallet = () => {
    console.log('return_wallet:', typeof window.return_wallet===typeof function(){});
    if(window.AraApp && typeof window.AraApp.return_wallet===typeof function(){} ) {
        window.AraApp.return_wallet();
    }
}

export const escapeHTML = (string) => {
    return (string+'').replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
}
export const unescapeHTML = (string) => {
    return (string+'').replace(/&amp;/g,'&').replace(/&lt;/g,'<').replace(/&gt;/g,'>');
}