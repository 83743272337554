import React from "react";
import { nComma } from "../utils/common";

const Item = ({ item, onDetail }) => {
  const { id, img, name, description, price } = item;
  return (
    <li
      className="coffee_item"
      onClick={e => {
        onDetail(id);
      }}
    >
      <img src={img} alt={`${name} 제품 이미지`} className="item_img" />
      <div className="item_dec">
        <span className="sub">{description}</span>
        <strong className="name">{name}</strong>
        <strong className="price">
          { (price+'').match(/[^0-9.-]/g) ? price : nComma(+price)}
        </strong>
      </div>
    </li>
  );
};

export default Item;
