import React, { useState, useEffect, useCallback } from "react";
import { nComma } from "../../utils/common";
import PageHeader from "../../components/PageHeader";
import Modal from "../../components/Modal";
import "../../assets/css/cart.css";

import {Model, addChangeListener} from "../../data/Model";
import {add_request_item, stop_request} from "../../utils/api";
import {showMessage} from "../../utils/common";
import moment from "moment";

// 데이터를 받아봐 주세요
import { getCart, removeCart, resetCart, addCart } from "../../utils/item";

// 이미지와 코인이름을 받아와 주세요
//import coinImg from "../../assets/images/cart/ara.png";
import coinImg from "../../assets/images/cart/gws.png";
let coinName = Model.symbol || "SIA";

// 데이터를 받아봐 주세요
let orderId = "";
let orderTime = "";
let point = 0;
const waiting_cnt = Model.shop_info ? Model.shop_info.waiting_cnt || 0 : 0;

const getShopInfo = () => {
    add_request_item('getShopInfo', {'branch_code':Model.branch_code}, function(r){
        if(r && r.success && r.payload) {
            Model.shop_info = r.payload;
        }
    })
}
getShopInfo();

// 사용자 지갑정보.
let wallet = {};
const setWallet = () => {
	if(Model.user_wallet && Model.user_wallet.length>0) {
		for( let i in Model.user_wallet ) {
			if(Model.user_wallet[i].symbol == Model.symbol) {
			wallet = Model.user_wallet[i];
			Model.coin_price = wallet.price_close;
				break;
			}
		}
	}
}

const Cart = ({ history }) => {

  if(!Model.branch_code || !Model.symbol || !Model.token) {
    history.push("/");
  }

  add_request_item('getBalance', {}, function(r){
    console.log('Cart getBalance:', r);
    if (r && r.success && r.payload) {
      Model.user_wallet = r.payload ? r.payload : [];
    }
  })
//   const display_dicimal = Model.coin_price>1000 ? ((Model.coin_price/1000)+'').length : 0; // 천원단위로 소슷점 반올림을 하려면 Model.coin_price/1000을 합니다.
  const display_dicimal = Model.coin_price ? ((Model.coin_price)+'').length : 0; // 천원단위로 소슷점 반올림을 하려면 Model.coin_price/1000을 합니다.

    // console.log('----------------------- start cart ---------------------------')
  const [items, setItems] = useState([]);
  const [symbol, setSymbol] = useState(Model.symbol);
  const [sum, setSum] = useState(0);
  const [sumCoin, setSumCoin] = useState(0);
  const [removeId, setRemoveId] = useState(0);
  const [removeModal, setRemoveModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [completionModal, setCompletionModal] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [cntWaiting, setCntWaiting] = useState(waiting_cnt);
  const [strWaiting, setStrWaiting] = useState(waiting_cnt);
console.log('symbol', symbol);

	const genStrWaiting = (cnt, status) => {
		return status==='ax' ? '취소되었습니다.' : cnt + '번째로 준비중입니다.';
	}

	const getOrderData = (_orderId, interval) => {
		add_request_item('getShopMyOrderInfo', {'order_code':_orderId}, function(r, i){
			// if(window.location.href.indexOf('/cart')==-1) {stop_request(i)}
			// console.log('getShopMyOrderInfo r:', r);
			// console.log('getShopMyOrderInfo r.payload.process:', r.payload.process);
			if(_orderId === orderId) {
				if(r && r.success && r.payload) {
					setCntWaiting( r.payload.waiting_cnt );
					setStrWaiting(genStrWaiting(r.payload.waiting_cnt, r.payload.process));
				} 
				if(r && r.success && r.payload && r.payload.process=='ax') { // 취소
					stop_request(i);
					if(window.location.href.indexOf('/cart')==-1 && Model.page_reflash) {
						Model.page_reflash();
					}
				}
				if(r && r.success && r.payload && r.payload.process=='ad') { // 완료
					// window.ara_notice();
					stop_request(i);
					// 팝업 닫고 주문목록으로 이동.
					onCompletionModal();
				}
			}
		}, interval)
	}
    
  const showReceiptModal = (order_code) => {
    // orderId = order_code;
    // console.log(items)
    setReceiptModal(true);
  }
  window.ara_notice = function(msg) {
    // let order_code = msg.split('주문번호:');
    // order_code = order_code[1] ? order_code[1].replace(/\s/g, '') : '';
    showReceiptModal();
  }

  const getSum = (() => {
    let sum = 0;
    let coin = 0;
    items.forEach(i => {
      if (i.checked) {
        let price = 
        sum += +(i.price * i.amount);
        coin += +( i.price * i.amount / Model.coin_price ).toFixed(display_dicimal);
      }
    });
    return { sum, coin: +coin.toFixed(display_dicimal) };
  })();

  // 삭제
  const onRemovCart = _ => {
    const newI = items.filter(i => i.id != removeId);
    setItems(newI);
    removeCart(removeId);
    toggleRemoveModal();
  };
  const openRemoveModal = id => {
    toggleRemoveModal();
    setRemoveId(id);
  };
  const toggleRemoveModal = _ => {
    setRemoveModal(!removeModal);
  };

  setWallet();

  const user_info = Model.user_info;
  // 상점 정보
  const shop_info = Model.shop_info;

  // Modal : 주문 step1
  const onSubmit = () => {
    // 주문금액확인.
    if(sumCoin>0) {
		// 잔액확인.
        if(wallet.sendable_amount>sumCoin) {
            toggleConfirmModal();
        } else {
            showMessage("warning", "잔액이 부족합니다.");
        }
    }
  };
  // Modal : 주문 step2 결제하시겠습니까?
  const onConfirmModal = () => {
    // orderId = "";
    // orderTime = "";
    // point = 0;
    // console.log('shop_info.wallet_name:', shop_info.wallet_name)
    add_request_item ('pay', {'branch_code':Model.branch_code, 'symbol':symbol, 'amount_krw':sum, 'amount':sumCoin, 'sender_walletname':user_info.userid, 'receiver_walletname':shop_info.wallet_name, 'goods':items}, function(r){
        if(r && r.success && r.payload) {
            orderId = r.payload.order_code;
            orderTime = moment(new Date().getTime()).format("HH:mm(MM-DD)"); // "13:31(19-11-01)";
            point = r.payload.bonus;
            setCntWaiting( r.payload.waiting_cnt);
            setStrWaiting(genStrWaiting(r.payload.waiting_cnt, r.payload.process));
            toggleConfirmModal();
			toggleCompletionModal();
            getOrderData(orderId, 2000);
        } else {
			console.log('r.error:', r.error);
            showMessage('error', r.error && r.error.message ? r.error.message : '결제가 처리되지 않았습니다.');
			if(r && r.error && r.error.code=='044') {
				setTimeout(function(){window.location.reload();}, 1800);
			}
        }
    })
  };
  const toggleConfirmModal = _ => {
    setConfirmModal(!confirmModal);
  };

  // Modal : 주문 step2
  const onCompletionModal = () => {
    toggleCompletionModal();
    // togglReceiptModal();
    resetCart();
    setItems([]);
	Model.force_order_reload = true;
	history.push("/order");
    // console.log('주문 끝');
  };
  const toggleCompletionModal = _ => {
    setCompletionModal(!completionModal);
  };

  // Modal : 주문 step3 제작 완료 팝업
  const onReceiptModal = () => {
    togglReceiptModal();
    history.push("/order");
  };
  const togglReceiptModal = _ => {
    setReceiptModal(!receiptModal);
  };
  const onCloseReceiptModal = _ => {
    togglReceiptModal();
    history.push("/list");
  };

  const plusAmount = id => {
    const newI = items.map(i => {
      if (i.id == id) {
        i.amount = i.amount || 1;
        i.amount++;
        addCart(i);
      }
      return i;
    });
    setItems(newI);
  };

  const minusAmount = id => {
    const newI = items.map(i => {
      if (i.id == id) {
        i.amount--;
        i.amount = i.amount || 1;
        addCart(i);
      }
      return i;
    });
    setItems(newI);
  };

  const onCheckCart = (id, value) => {
    const newI = items.map(i => {
      if (i.id == id) {
        typeof i.checked !== "boolean"
          ? (i.checked = false)
          : (i.checked = !value);
      }
      return i;
    });
    setItems(newI);
  };

  const addItem = () => {
    history.push(`/list/`);
  };

  const onChangeCurrency = (event) => {
	  console.log('event.target.value:',event.target.value)
	  Model.symbol = event.target.value;
    setWallet();
    coinName = Model.symbol;
    setSymbol(Model.symbol);
  }
  const onClickCurrency = (event) => {
	  console.log('onClickCurrency start');
  }

  useEffect(() => {
    const initCartData = getCart().map(i => {
      typeof i.checked !== "boolean" && (i.checked = true);
      typeof i.amount !== "number" && (i.amount = 1);
      return i;
    });
    setItems(initCartData);
  }, []);

  useEffect(() => {
    setSum(getSum.sum);
    setSumCoin(getSum.coin);
  }, [getSum.coin, getSum.sum]);

  return (
    <div className="cart_wrap">
      <PageHeader history={history} name="WKON*AISUL" subName="카트" />
      <div className="cart_list">
        {items.map(i => (
          <CartItem
            key={i.id}
            item={i}
            openRemoveModal={openRemoveModal}
            onCheckCart={onCheckCart}
            minusAmount={minusAmount}
            plusAmount={plusAmount}
          />
        ))}
        <button type="button" className="btn_add" onClick={addItem}>
          메뉴 추가 +
        </button>
      </div>
      {/* <CartBottom sum={sum} sumCoin={sumCoin} onSubmit={onSubmit} /> */}

	  <div className="cart_bottom">
      <div>
        주문 합계
        <span className="sum">{nComma(sum)}</span>
        <div className="sum_coin">
			{nComma(sumCoin)}
			{/* <span className="symbol" onClick={onClickCurrency}>{symbol}</span> */}
			<select id="select_currency" name="select_currency" onChange={onChangeCurrency} value={symbol}>
			{Model.user_wallet.map(i => (
				<option value={i.symbol}>{i.symbol}</option>
			))}
			</select>
		  </div>
      </div>
      <button type="button" onClick={onSubmit} className="btn_submit">
        <img src={coinImg} alt="" className="coin_icon" />
        {coinName}로 결제
      </button>
    </div>

      <Modal
        className="remove_modal"
        active={removeModal}
        confirm={onRemovCart}
        cancel={toggleRemoveModal}
        close={toggleRemoveModal}
      >
        삭제하시겠습니까?
      </Modal>

      <Modal
        className="confirm_modal"
        active={confirmModal}
        confirm={onConfirmModal}
        cancel={toggleConfirmModal}
        close={toggleConfirmModal}
      >
        {/* <div className="title">주문ID : {orderId}</div> */}
        <p className="question">아래와 같이 결제하시겠습니까?</p>
        <ul className="confirm_modal_items">
          {items.map(i => (
            <li key={i.id}>
              <span className="name">{i.name}</span>
              <span className="amount">{nComma(i.amount)}개</span>
            </li>
          ))}
          <li className="sum">
            <span className="name">주문합계</span>
            <span className="amount">￦{nComma(sum)}</span>
          </li>
        </ul>
        <div className="total">
          <span className="name">결제금액</span>
          <span className="amount">
            {nComma(sumCoin)} {coinName}
          </span>
        </div>
        <div className="waiting_cnt">
            <span className="">대기주문 {cntWaiting}건</span>
        </div>
      </Modal>
      <Modal
        className="completion_modal"
        active={completionModal}
        confirm={onCompletionModal}
        // close={toggleCompletionModal}
        close={onCompletionModal}
      >
        <div className="title">
          주문ID : {orderId}
          <br />
          상태 : {strWaiting}
        </div>
        <p className="dec">
		  WKON*AISUL Coffee에서 주문하신{" "}
          {(() => { 
            if(items.length > 1) {
                return (<strong>&lt;{items[0] && items[0].name} 외… {items.length - 1}개&gt;</strong>)
            } else {
                return (<strong>&lt;{items[0] && items[0].name} &gt;</strong>)
            }
          })()}
          결제되었습니다.
        </p>
        <p className="save">
          <span className="point">{point} SIA</span> 가 적립되었습니다.
        </p>
        <p className="ani"></p>
      </Modal>
      <Modal
        className="receipt_modal"
        active={receiptModal}
        confirm={onReceiptModal}
        close={onCloseReceiptModal}
      >
        <div className="title">
          주문ID : {orderId}
          <br />
          주문시간 : {orderTime}
        </div>
        <p className="dec">
          WKON*AISUL Coffee에서 주문하신{" "}
          {(() => { 
            if(items.length > 1) {
                return (<strong>&lt;{items[0] && items[0].name} 외… {items.length - 1}개&gt;</strong>)
            } else {
                return (<strong>&lt;{items[0] && items[0].name} &gt;</strong>)
            }
          })()}
          준비되었습니다.
        </p>
        <p className="announcement">
          <span>카운터에서 수령하세요.</span>
        </p>
        <div className="sum">
          <span className="name">주문합계</span>
          <span className="amount">￦{nComma(sum)}</span>
        </div>
        <div className="total">
          <span className="name">결제금액</span>
          <span className="amount">
            {sumCoin} {coinName}
          </span>
        </div>
      </Modal>
    </div>
  );
};

const CartItem = ({
  item,
  openRemoveModal,
  onCheckCart,
  minusAmount,
  plusAmount
}) => {
  const { checked, id, name, price, amount, img } = item;

  const onItemCheckCart = e => {
    onCheckCart(id, e.target.checked);
  };
  const itemOpenRemoveModal = () => {
    openRemoveModal(id);
  };
  const itemMinusAmount = () => {
    minusAmount(id);
  };
  const itemPlusAmount = () => {
    plusAmount(id);
  };

  return (
    <div className={`cart_item ${!!checked ? "checked" : "unchecked"}`}>
      <button
        type="button"
        className="btn_remove"
        onClick={itemOpenRemoveModal}
      >
        삭제
      </button>
      <label className="cart_label" htmlFor={id}></label>
      <p className="title">
        <input id={id} type="checkbox" onChange={onItemCheckCart} />
        {name}
        <strong className="price">{price}</strong>
      </p>

      <div className="amount">
        수량
        <div className="amount_box">
          <button className="btn_minus btn_amount" onClick={itemMinusAmount}>
            빼기
          </button>
          {amount}
          <button className="btn_plus btn_amount" onClick={itemPlusAmount}>
            더하기
          </button>
        </div>
      </div>
      <div className="total">
        <span className="pri">{nComma(price * amount)}</span>
      </div>
      <img src={img} alt="" className="cart_img" />
    </div>
  );
};
/*
const CartBottom = ({ sum, sumCoin, onSubmit }) => {

	const onChangeCurrency = (event) => {
	  Model.symbol = event.target.value;
	  setWallet();
	}
	const onClickCurrency = (event) => {
		
	}
  
  return (
    <div className="cart_bottom">
      <div>
        주문 합계
        <span className="sum">{nComma(sum)}</span>
        <div className="sum_coin">
			{sumCoin}<span className="symbol" onClick={onClickCurrency}>{Model.symbol}</span>
			<select id="select_currency" name="select_currency" onChange={onChangeCurrency} value={Model.symbol} style={{'display':'none'}}>
			{Model.user_wallet.map(i => (
				<option value={i.symbol}>{i.name}</option>
			))}
			</select>
		  </div>
      </div>
      <button type="button" onClick={onSubmit} className="btn_submit">
        <img src={coinImg} alt="" className="coin_icon" />
        {coinName}로 결제
      </button>
    </div>
  );
};
*/
export default Cart;
