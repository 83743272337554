import React from "react";
import "./App.css";
import "./assets/css/vanillatoasts.css";
import Routes from "./components/Routes";

// import {Model} from "./data/Model";
// import { messaging } from "./utils/firebase";

// 개발환경에서는 firebase 작동 안시킵니다.
// messaging.requestPermission()
// .then(function() {
//   // console.log('허가!');
//   return messaging.getToken(); //토큰을 받는 함수를 추가!
// })
// .then(function(token) {
//   // console.log('fcn token:',token); //토큰을 출력!
//   Model.fcm_token = token;
// })
// .catch(function(err) {
//   // console.log('fcm에러 : ', err);
// 	window.showMessage('warning', '알림설정을 허가하셔야 주문정보를 빠르게 확인하실 수 있습니다. 알림설정을 허용해 주세요. <img src="https://static.icon.town/icondata/2018/08/a16.png" style="width:100%"/>', '시스템 알림',60*60*24*365);
// })

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
