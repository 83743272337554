import React from "react";
import "../assets/css/modal.css";

const Modal = ({ className, active, children, confirm, cancel, close }) => {
  return (
    <div className={`modal ${active ? "active" : ""} ${className || ""}`}>
      <div className="modal_dialog">
        <div className="modal_content">
          <div className="modal_header">
            <button type="button" className="close" onClick={close}>
              닫기
            </button>
          </div>
          <div className="modal_body">{children}</div>
          <div className="modal_footer">
            {cancel && (
              <button type="button" className="btn" onClick={cancel}>
                취소
              </button>
            )}
            {confirm && (
              <button type="button" className="btn primary" onClick={confirm}>
                확인
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
