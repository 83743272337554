// proxy 방식
// https://meetup.toast.com/posts/158
import moment from "moment";

const sha1 = require('sha1');

let __listener__ = {},
    __data__ = {}, // in memory storage (delete on reload, default)
    session_item = ['branch_code','shop_info','token','selected_top_menu','symbol','gws_price','user_wallet','goods_list','coin_price','user_info'], // item name using session storage ( delete on close )
    local_item = ['fcm_token'] // item name using local storage ( delete user action or cleaner programes )
    ;

const handler = {
    get(target, property) {
        let r = target[property];
        if(session_item.indexOf(property)>-1) {
            r = JSON.parse(sessionStorage.getItem(property)||false);
        }
        if(local_item.indexOf(property)>-1) {
            r = JSON.parse(localStorage.getItem(property)||false);
        }
        return r;
    },
    set(target, property, value) {
        const oldValue = target[property];
        try {
            if(session_item.indexOf(property)>-1) {
                sessionStorage.setItem(property, JSON.stringify(value));
            } else if(local_item.indexOf(property)>-1) {
                localStorage.setItem(property, JSON.stringify(value));
            } else {
                target[property] = value;
            }
            // Notify model changes
            // console.log('same?', JSON.stringify(oldValue) == JSON.stringify(value));
            if (__listener__[property] && JSON.stringify(oldValue) !== JSON.stringify(value)) {
                const cb = __listener__[property]
                for (var n in cb) {
                  // console.log('call callback function. property :', property, ', fn:' ,cb[n]);
                    cb[n](property, oldValue, value);
                }
            }
        } catch (e) {}
        // Return true if successful. In strict mode, returning false will throw a TypeError exception.
        return true;
    }
};
export const Model = new Proxy(__data__, handler);
window.Model = Model;
console.log('Model:', Model);

// ---------------- set default values --------------------------------

Model.__default_symbol__ = 'SIA';
console.log('Model.__default_symbol__:',Model.__default_symbol__);
Model.coin_price = 1;
console.log('Model.coin_price:',Model.coin_price);
Model.branch_code = 12;
console.log('Model.branch_code:', Model.branch_code);



/**
 * @param String property 감지할 데이터이름 
 * @param String name 콜백이름(중복방지용)
 * @param Function listener 콜백함수
 */
export const addChangeListener = (property, name, listener) => {
    const key = sha1(name);
    // 단일 콜백.
    // __listener__[property] = listener; 
    // 다중 콜백.
    if (__listener__[property]) {
        __listener__[property][key] = listener;
    } else {
        __listener__[property] = {};
        __listener__[property][key] = listener;
    }
    // console.log('__listener__:', __listener__);
    // console.log('property:', property);
}


// {
//   no: "123",
//   user_id: "ARA-123456",
//   state: "결제",
//   content:
//     "아메리카노(hot) 2개, 아메리카노(ice) 2개, 아메리카노(hot) 2개, 아메리카노(ice) 2개",
//   total: "￦7,000 / 0.005 GWS / 70P",
//   date: "13:16(19-11-07)",
//   order_list: [
//     { name: "아메리카노(hot)", amount: 2 },
//     { name: "아메리카노(ice)", amount: 2 },
//     { name: "에스프레소(hot)", amount: 3 }
//   ]
// },
export const convertOrderData = (order_data) => {
  if(!order_data) { return []; }
  let r = [];
  for (let i = 0; i < order_data.length; i++) {
    let t = {},
    order = order_data[i];
    t.order_code = order.order_code;
    t.id = order.order_code;
    t.no = order.order_code; // 매일초기화할수있음.
    t.user_id = order.userid;
    t.order_price = order.order_amount;
    t.pay_price = order.pay_amount;
    t.pay_symbol = order.pay_symbol;
    t.amount = order.amount;
    t.state_code = order.process;
    switch (order.process) {
        case 'ab': // 준비중(결제완료, 접수대기)
        t.state = '대기중';
        t.state_next = '접수';
        break; 
        case 'ac': // 음료 만들기 시작(접수, 작업중)
        t.state = '접수';
        t.state_next = '완료';
        break;
        case 'ad': // 음료 다 만들었음.  대기중
        t.state = '완료';
        t.state_next = '거래 완료'; // 다음단계
        break; 
        case 'ae': // 물건 가져감. 
        t.state = '거래 완료'; // 다음단계
        t.state_next = ''; // 다음단계
        break; 
        case 'ax': // 취소(환불)
        t.state = '취소';
        t.state_next = ''; // 다음단계
        break; 
    }
    // console.log('t.state:',t.state);
    t.content = "아메리카노(hot) 2개, 아메리카노(ice) 2개, 아메리카노(hot) 2개, 아메리카노(ice) 2개";
    t.total_price = order.order_amount;//"￦7,000 / 0.005 GWS / 70P";
    t.total_coin = '';
    t.total = "￦"+order.order_amount+" / 0.005 GWS / 70P";//"￦7,000 / 0.005 GWS / 70P";
    t.time = order.regdate>0 ? order.regdate*1000 : (new Date()).getTime();
    t.date = moment(t.time).format("HH:mm(MM-DD)"); // "13:16(19-11-07)";
    t.orders = [];
    let goods = order.goods;
    if(goods) {
        for (let g = 0; g < goods.length; g++) {
            let order_goods = goods[g];
          t.orders.push({'name':order_goods.goods_name, 'amount':order_goods.order_qty});
        }
    }
    t.list = t.orders;
    r.push(t);
  }
  return r;
}
