import React from "react";
import "../../assets/css/loading.css";

import {Model} from "../../data/Model";
import {add_request_item, stop_request} from "../../utils/api";
import {getURLParameter, return_wallet, showMessage} from "../../utils/common";

let _cnt_loaded = 0, _cnt_item = 0;


if(getURLParameter('branch_code')) {
    Model.branch_code = getURLParameter('branch_code').toUpperCase(); // 1: testing
    const _msg_no_shop_info = "상점 정보가 없습니다.";
    add_request_item('getShopInfo', {'branch_code':Model.branch_code}, function(r){
        if(r && r.success && r.payload) {
            Model.shop_info = r.payload;
            _cnt_loaded++;
        } else {
            console.error(_msg_no_shop_info);
            showMessage("error", _msg_no_shop_info);
        }
    })
    _cnt_item++;
}

// if(getURLParameter('symbol')) {
    Model.symbol = getURLParameter('symbol') || Model.symbol || Model.__default_symbol__;
    const _msg_no_price = Model.symbol+"의 가격을 가져오지 못했습니다.";
    add_request_item('getExchangePrice', {'symbol':Model.symbol}, function(r){
        if(r && r.success && r.payload && r.payload[0] && r.payload[0].price_close>0) {
            Model.coin_price = r.payload[0].price_close;
            // _cnt_loaded++;
        } else {
            Model.coin_price = Model.coin_price ? Model.coin_price : 1;
            console.error(_msg_no_price);
            showMessage("error", _msg_no_price);
        }
    })
    // _cnt_item++;
// }

if(getURLParameter('token')) {
    Model.token = getURLParameter('token');
    add_request_item('getMyInfo', {}, function(r){
        if(r && r.success && r.payload) {
			Model.user_info = r.payload;
            _cnt_loaded++;
        } else {
            let _msg_no_user = "사용자 정보를 찾지 못했습니다.";
            console.error(_msg_no_user);
            showMessage("error", _msg_no_user);
            return_wallet();
        }
    })
    _cnt_item++;
    add_request_item('getBalance', {}, function (r) {
        console.log('Loading getBalance:', r);
        if(r && r.success && r.payload) {
            Model.user_wallet = r.payload ? r.payload : [];
            _cnt_loaded++;
        } else {
            let _msg_no_user = Model.symbol+"의 사용자 지갑 정보를 찾지 못했습니다.";
            console.error(_msg_no_user);
            showMessage("error", _msg_no_user);
            return_wallet();
        }
    })
    _cnt_item++;
}

// 결제용 금액 시세 저장.
// add_request_item('getExchangePrice', {'symbol':'GWS'}, function(r){
//   if(r && r.success && r.payload) {
//       Model.coin_price = r.payload[0].price_close;
//   }
// }, 60000);
// 상품정보 가져오기.
if(Model.branch_code) {
    add_request_item('getShopGoodsList', {'branch_code':Model.branch_code}, function(r, i){
        if(window.location.href.indexOf('/list')==-1) {stop_request(i)}
        if(r && r.success && r.payload) {
            Model.goods_list = r.payload;
        }
    },10*60000);
}

const Loading = ({ history }) => {

    const check_loaded = () => {
        if(_cnt_loaded<_cnt_item) {
            setTimeout(() => {check_loaded()}, 800);
        } else {
            if(!Model.branch_code) {setTimeout(function(){showMessage("error", '상점 정보를 보내주세요.');}, 1000);}
            if (!Model.symbol) { setTimeout(function () { showMessage("error", '결제화폐 정보를 보내주세요.'); }, 1000); }
            // http://localhost:3001/?branch_code=12&token=c74f04b6685ff914481f72fc5a497387b14b32d0
            if (!Model.token) {
                // setTimeout(function () { showMessage("error", '로그인 연동 정보를 보내주세요.'); }, 1000);
                history.push("/login/");
            }
            console.log(Model.branch_code && Model.symbol && Model.token);
            if(Model.branch_code && Model.symbol && Model.token) {
                history.push("/list/");
                setTimeout(function(){window.location.reload();},200);
            }
        }
    };check_loaded();

  return (
    <div className="Loading">
      <div className="inner">
        {/* <p className="sub_title">모바일로 주문하고 SIA로 결제하는</p> */}
        <p className="sub_title">모바일로 주문하는</p>
        <h1 className="logo">WKON*AISUL Coffee</h1>
        <p className="copy">www.siaplay.kr</p>
      </div>
    </div>
  );
};

export default Loading;
