import img from "../assets/images/item/invalid-name.png";
import hotCoffee from "../assets/images/item/hot-coffee.png";
import iceCoffee from "../assets/images/item/ice-coffee.png";
import hotLatteCoffee from "../assets/images/item/hot-latte-coffee.png";
import iceLatteCoffee from "../assets/images/item/ice-latte-coffee.png";
import subImg from "../assets/images/item/122-x-110@3x.png";

const coffees = [
  // {
  //   id: 1000,
  //   category: "Hand Drip",
  //   productList: [
  //   ]
  // },
  {
    id: 2000,
    category: "Coffee",
    productList: [
      {
        id: 5,
        name: "아메리카노 (ICE)",
        description: "아아는 진리지!",
        img: iceCoffee,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 6,
        name: "아메리카노 (HOT)",
        description: "따듯한 아메리카노",
        img: hotCoffee,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 7,
        name: "라떼는 말이야 (ICE)",
        description: "풍부한 우유 거품을 시원하게",
        img: iceLatteCoffee,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 8,
        name: "라떼는 말이야 (HOT)",
        description: "풍부한 우유 거품을 따듯하게",
        img: hotLatteCoffee,
        price: "0",
        coin: 0,
        amount: 0,
      }
    ]
  },
  {
    id: 3000,
    category: "Tea & Ade",
    productList: [
      {
        id: 10,
        name: "히비스커스",
        description: "",
        img,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 11,
        name: "케모마일",
        description: "",
        img,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 12,
        name: "보이차",
        description: "",
        img,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 13,
        name: "레몬밤",
        description: "",
        img,
        price: "0",
        coin: 0,
        amount: 0,
      },
      {
        id: 14,
        name: "루이보스",
        description: "",
        img,
        price: "0",
        coin: 0,
        amount: 0,
      }
    ]
  },
  // {
  //   id: 4000,
  //   category: "Dessert",
  //   productList: [
  //   ]
  // }
];

export default coffees;
