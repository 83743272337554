import React, { useState, useEffect } from "react";
import ItemHeader from "../../components/ItemHeader";
import Item from "../../components/Item";

// 데이터를 받아와 주세요
import { getItems, getSubProductList, addCart } from "../../utils/item";

const ItemList = ({ match, history }) => {
  const { id, category } = match.params;
  const menu = getItems(category).category;
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(getSubProductList(id));
  }, [id]);

  const move = id => {
    history.push(`/cart`);
    addCart(items.filter(i => i.id == id)[0]);
  };

  return (
    <div className="item_wrap">
      <ItemHeader history={history} menu={menu} />
      <ul className="item_list">
        {items.map(item => (
          <Item item={item} key={item.id} onDetail={move} />
        ))}
      </ul>
    </div>
  );
};

export default ItemList;
