import React from "react";
import useForm from "react-hook-form";
import * as common from "../../utils/common";
import * as API from "../../utils/api";
import { Model } from "../../data/Model";

import "../../assets/css/login.css";
import "../../assets/css/vanillatoasts.css";

const Login = ({ history }) => {


  // check login 
  if(Model.token) { history.push("/main"); }

  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    // if(!data.branch_code) {
    //   common.showMessage('warning', '매장을 선택해주세요.'); return false;
    // }
    API.add_request_item('socialLogin', {'social_id':data.userid, 'social_name':'', 'userpw':data.user_password, 'branch_code':data.branch_code}, function(r){
      if(r && r.success && r.payload.token) {
        Model.token = r.payload.token;
        if(data.userid) Model.user_id = data.userid;
        if(data.branch_code) Model.branch_code = data.branch_code;
        history.push("/list");
        setTimeout(function(){window.location.reload();}, 200);
      } else {
        Model.token = '';
        Model.branch_code = 0;
        common.showMessage('error', '계정정보를 확인해주세요.', '로그인 실패'); return false;
      }
    });
  };

  // const tel = Model.shop_info ? Model.shop_info.phone : '02-557-3521';
  // const telLink = 'tel:' + tel;
  // const shopName = Model.shop_info ? Model.shop_info.branch_name : ''

  return (
    <div class="login_wrap">
      <div class="login_box">
        <h1 class="logo">WKON*AISUL Coffee</h1>
        {/* <p class="dec">Login in.</p> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="text" name="userid" placeholder="User id" ref={register} value="lilove77" />
          <input type="password" name="user_password" placeholder="Password" ref={register} value="111111" />
          <button type="submit" class="login_btn">Login</button>
        </form>
        <p class="copyright">WKON*AISUL COFFEE, Inc. © 2023</p>
      </div>
    </div>
  );
};

export default Login;
