import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { nComma, return_wallet } from "../../utils/common";
import PageHeader from "../../components/PageHeader";
import Modal from "../../components/Modal";

import "../../assets/css/order.css";

import {Model, convertOrderData, addChangeListener} from "../../data/Model";
import {add_request_item, stop_request} from "../../utils/api";
// import { addCallback } from "../../utils/firebase";
import ReactPaginate from 'react-paginate';

let this_page = 1;
let cnt_age = 10;
let set_total_page = () => {};// 랜더링시 total_page를 변경할 수 있도록 빈 함수 하나 생성함. 화면 랜더링방식이 함수 방식이라 Order가 계속 실행됨. Order속에서 ajax 요청하면 화면의 값이 바뀔때마다 ajqx 요청하게되고 반복 요청시 중첩되는 핼게이트가 오픈됨. 
let check_time = 400;
const getOrderData = (interval) => {
	let run_ajax = true;
	run_ajax = window.location.href.indexOf('/order')==-1 ? false : true;
	if(run_ajax && Model.order_list) {
		let n = Model.order_list.length;
		Model.order_list.map((item) => {
			if(item.process==='ab' || item.process==='ac' || item.process==='ad') { //대기중, 접수, 완료 상태가 있으면 ajax 작동.
				n--;
			}
		});
		// 대기중, 완료, 거래완료 상태가 하나라도 없으면 ajax 로 데이터를 안가져 옵니다. 
		if(n === Model.order_list.length) {
			run_ajax = false;
		}
	}
	if(!interval || Model.force_order_reload) {
		run_ajax = true;
	}
	if(run_ajax) {
		if(interval<1000) {
			interval = 3000;
		}
	} else {
		interval = check_time;
	}
	if(interval>0) {
		setTimeout(function(){getOrderData(interval)}, interval);
	}
	if(run_ajax) {
		add_request_item('getShopMyOrderList', {'branch_code':Model.branch_code,'status':'history', 'page':this_page, 'rows':cnt_age}, function(r, i){
			if(r && r.success && r.payload) {
				Model.order_list = r.payload;
				let total_rows = r.payload[0].total_rows ? r.payload[0].total_rows : 0;
				set_total_page( total_rows ? Math.ceil(total_rows / cnt_age) : 1 );
			}
			Model.force_order_reload = false;
		});
	}
}
getOrderData(check_time);

const Order = ({ history }) => {
  const [items, setItems] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [thisPage, setThisPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1); // = Math.ceil(data.meta.total_count / data.meta.limit),
  const [totalRows, setTotalRows] = useState(1); // Math.ceil(data.meta.total_count / data.meta.limit),

  if(!Model.branch_code || !Model.symbol || !Model.token) {
    history.push("/");
  }

  set_total_page = ( n ) => {
	setTotalPage( n );
  }

  let orderId = '';
  let orderTime = '';
  let sum = '';
  let sumCoin = '';
  let coinName = Model.symbol;

  // 외부 모듈이 데이터 갱신 시킬 수 있도록 등록
  Model.page_reflash = () => {
	this_page = 1;
	getOrderData();
  }
  
  const userName = Model.user_info ? Model.user_info.name : ''; //"ARA-10000127";

  addChangeListener('order_list','Order/index.js', function(){
    setItems( convertOrderData(Model.order_list) );
  });

  useEffect(() => {
    setItems( convertOrderData(Model.order_list) );
  }, []);

  const onRemoveOrder = id => {
    const newItem = items.filter(i => i.id != id);
    setItems(newItem);
  };

  const toggleCloseModal = _ => {
    setCloseModal(!closeModal);
  };

  const logout = () => {
    add_request_item('logout', {}, function(r){
        Model.token = null;
        Model.user_id = '';
        history.push("/login");
    });
  }
  const onClose = _ => {
    toggleCloseModal();
    return_wallet();
    logout();
  };

  const handlePageClick = data => {
	this_page = data.selected+1;
	setThisPage(this_page);
    getOrderData();
  };

  return (
    <div className="order_wrap">
      <PageHeader history={history} name="WKON*AISUL" subName="주문" />
      <div className="order_bar">
        <h2>주문 내역</h2>
        <div className="name">{userName}</div>
      </div>
      <div className="order_list">
        {items.map(order => (
          <OrderItem
            order={order}
            key={order.no}
            onRemoveOrder={onRemoveOrder}
          />
        ))}

		{totalPage>1 && 
		<div className="pagination_wrap">
			<ReactPaginate
				previousLabel={'<'}
				nextLabel={'>'}
				breakLabel={'...'}
				breakClassName={'break-me'}
				pageCount={totalPage}
				marginPagesDisplayed={2}
				pageRangeDisplayed={5}
				onPageChange={handlePageClick}
				containerClassName={'pagination'}
				pageClassName={'page-item'}
				previousClassName={'page-item'}
				nextClassName={'page-item'}
				breakClassName={'page-item'}
				activeClassName={'active'}
			/>
		</div>
		}
      </div>
		<Link to="/enquire" className="link_qna">
			고객 문의 >
		</Link>
		<a className="btn_app_close" onClick={toggleCloseModal}>
			WKON*AISUL Coffee 종료하기
		</a>
      <Modal
        className="close_modal"
        active={closeModal}
        confirm={onClose}
        cancel={toggleCloseModal}
        close={toggleCloseModal}
      >
        WKON*AISUL Coffee를 종료하시겠습니까?
      </Modal>

    </div>
  );
};

const OrderItem = ({ order, onRemoveOrder }) => {
  const [active, setActive] = useState(false);
  const { no, state, list, date, id, state_code, amount, order_price, pay_price, pay_symbol } = order;

  const toggleClass = () => {
    setActive(!active);
  };
  const itemRemoveOrder = () => {
    onRemoveOrder(id);
    add_request_item('deleteShopOrder', {'order_code':id}, function(r){
        if(r && r.success && r.payload) {
            Model.order_list = Model.order_list.filter( item => item.id!==id );
        } else {
            // getOrderData();
        }
    })
    
  };

  const stateClass = (() => {
    let str = "";

    switch (state_code) {
      case "ab":// "결제, 대기중":
        str = "state_1";
        break;
      case "ac": // "접수,준비중":
        str = "state_2";
        break;
      case "ad": // "완료":
        str = "state_3";
        break;
      case "ae": // "거래완료":
        str = "state_4";
        break;

      default:
        break;
    }
    return str;
  })();

  return (
    <dl
      className={`order_item ${active ? "open" : "close"} ${stateClass}`}
      onClick={toggleClass}
    >
      {!active ? (
        <dt className="item_title">
          <span className="state">{state}</span>
          {(() => { 
            if(list.length > 1) {
                return (<p>{`${list[0].name} 외 ${list.length}개`}</p>)
            } else {
                return (<p>{`${list[0].name} `}</p>)
            }
          })()}
          <span className="date">{date}</span>
        </dt>
      ) : (
        <dd className="item_body">
          {state_code>='ad' ? (
          <button
            type="button"
            className="btn_remove"
            onClick={itemRemoveOrder}
          >
            삭제
          </button>
          ) : (
              <span></span>
          )}
          <p className="order_tit">
            <span className="state">{state}</span>
            <strong className="no">주문 No : {no}</strong>
          </p>
          <p className="order_dec">
            주문 ID: {id} <br /> 주문 시간 :{date}
          </p>
          <ul className="order_detail">
            {list.map(i => (
              <li key={i.name}>
                <span className="name">{i.name}</span>
                <span className="amount">{i.amount}개</span>
              </li>
            ))}
            <li className="sum">
              <span className="name">주문합계</span>
              <span className="amount">￦{nComma(+order_price)}</span>
            </li>
          </ul>
          <div className="payment_amount">{nComma(+pay_price)} {pay_symbol}</div>
        </dd>
      )}
    </dl>
  );
};

export default Order;
