import React, { useState, useEffect } from "react";
import "../assets/css/item.css";
import { getCategoryItems } from "../utils/item";

import { Model } from "../data/Model";

const coffeeNavs = ["Hand Drip", "Coffee", "Tea & Ade", "Dessert"];

const Header = ({ history, menu }) => {
  const [page, setPage] = useState(menu || coffeeNavs[0]);
  const shop_name = Model.shop_info ? Model.shop_info.branch_name : '';

  useEffect(() => {
    setPage(menu || Model.selected_top_menu || coffeeNavs[0]);
  }, [menu]);

  const onClickMenu = nav => {
    const tItem = getCategoryItems(nav);
    if(tItem && tItem.id) {
        const id = getCategoryItems(nav).id;
        Model.selected_top_menu = nav;
        setPage(nav);
        history.push(`/list/${id}`);
    }
  };

  const CoffeeNav = () =>
    coffeeNavs.map(nav => (
      <li
        key={nav}
        className={nav === page ? "active" : ""}
        onClick={_ => onClickMenu(nav)}
      >
        {nav}
      </li>
    ));

  const moveCart = () => {
    history.push("/cart");
  };

  const moveOrder = () => {
    history.push("/order");
  };

  const moveIndex = () => {
    history.push("/list");
  };

  return (
    <div className="header_coffee">
      <div className="header">
        <button className="btn_my" onClick={moveOrder}>
          My WKON*AISUL Coffee
        </button>
        <h1 className="logo" onClick={moveIndex}>
		  WKON*AISUL Coffee
        </h1>
        <button onClick={moveCart} className="btn_cart">
          cart
        </button>
      </div>
      <div className="store_bar">-매장 : {shop_name}</div>
      <ul className="coffee_nav">{CoffeeNav()}</ul>
    </div>
  );
};

export default Header;
