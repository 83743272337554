const KEY = "WKON*AISUL Coffee";

export const createLocalStorage = (key, data) => {
  if (!key) {
    console.error("createLocalStorage not key");
    return;
  }
  const localData = readLocalStorage();
  const newData = {
    ...localData,
    [key]: data
  };
  console.log(newData);
  localStorage.setItem(KEY, JSON.stringify(newData));
};

export const readLocalStorage = key => {
  const data = localStorage.getItem(KEY);
  const store = data ? JSON.parse(data) : {};
  return key ? store[key] : store;
};

export const updateLocalStorage = (key, data) => {
  if (!key) {
    console.error("updateLocalStorage not key");
    return;
  }
  const localData = readLocalStorage();
  let newData = {
    ...localData,
    [key]: data
  };
  localStorage.setItem(KEY, JSON.stringify(newData));
};

export const deleteLocalStorage = key => {
  if (!key) {
    localStorage.removeItem(KEY);
    return;
  }
  const localData = readLocalStorage();
  delete localData[key];
  localStorage.setItem(KEY, JSON.stringify(localData));
};
