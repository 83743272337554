import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {Model, convertOrderData, addChangeListener} from "./data/Model";
// console.info('Model.branch_code:');console.info(Model.branch_code);


ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
