import DATA from "../data/coffees";
// import ORDER from "../data/order";
import {unescapeHTML} from "./common";
import {Model} from "../data/Model";
import {
  createLocalStorage,
  readLocalStorage,
  updateLocalStorage
} from "./localStorage";

const cart = "CART";
// const DATA = Model.goods_list || [];
Model.goods_list = DATA;
console.log('DATA:',DATA)

const productList = (() => {
  let list = [];
//   DATA.forEach(d => (list = [...list, ...d.productList]));
  DATA.forEach(d => {
    if(d.productList){ list = [...list, ...d.productList] }
  });
  return list;
})();

// 카테고리 목록 데이터
export const getCategorys = () => DATA.map(d => d.category);

// 카테고리에 따른 아이템
export const getCategoryItems = category =>
  DATA.filter(d => unescapeHTML(d.category) == unescapeHTML(category))[0];

// 아이디에 따른 아이템
export const getItems = (id, target) => {
    target = target ? target : DATA;
    let item = target.filter(d => d.id == id)[0];
    if(!item) {
        for( var i=0; i<target.length; i++) {
            if(target[i].productList) {
                item = getItems(id, target[i].productList);
                if(item) {
                    return item;
                }
            }
        }
    }
    // let item = target.filter(d => d.id == id)[0];
    return item;
}

// 아이템의 아이디들
export const getItemsId = () => DATA.map(d => d.id);

// 아이템 전체
export const getItem = id => productList.filter(i => i.id == id)[0];

// 아이디에 따른 서브 목록
export const getSubProductList = id => getItem(id).SubMenu;

// 카트는 로컬스토리지에 저장해서 사용하고 있습니다.
// 서버에 저장하는 경우 수정 해서 사용 부탁드립니다.
// 카트 목록
export const getCart = () => {
    return readLocalStorage(cart) || [];
}

// 카트에 추가
export const addCart = ({ id, name, img, price, coin, amount }) => {
  const item = { id, name, img, price, coin, amount };

  if (!readLocalStorage(cart)) {
    createLocalStorage(cart, [item]);
    return;
  } else {
    let d=0, before = readLocalStorage(cart);
    for(let i=0; i<before.length; i++) {
        if(before[i].id===id) {
            d=1;
            console.log('add cart item before[i].amount:', before[i].amount, amount);
            before[i].amount = amount ? amount : before[i].amount+1;
        }
    }
    if(!d) {
        item.amount = 1;
        before = [...before, item];
    }
    updateLocalStorage(cart, before);
  }
};

// 카트 삭제
export const removeCart = id => {
  updateLocalStorage(
    cart,
    readLocalStorage(cart).filter(i => i.id !== id)
  );
};

// 카트 목록 리셋
export const resetCart = _ => {
  updateLocalStorage(cart, []);
};

// order 리스트
// export const getOrder = () => ORDER;

export default {
  getCategorys,
  getItemsId,
  getCategoryItems,
  getItems,
  getItem,
  getSubProductList,
  getCart,
  addCart,
  resetCart
};
