import React from "react";
import "../assets/css/pageHeader.css";

const PageHeader = ({ history, name, subName }) => {
  const back = () => {
    history.goBack();
  };

  return (
    <div className="page_header">
      <button className="btn_back" onClick={back}>
        뒤로가기
      </button>
      <h1>
        {name}
        <span>{subName}</span>
      </h1>
    </div>
  );
};

export default PageHeader;
